import { Injectable } from '@angular/core'

const LocalStorageConst = {
    TOKEN: 'token',
    CURRENT_USER: 'currentUser',
    CURRENT_SCHOOL: 'currentSchool',
    CURRENT_SCHOOL_YEAR: 'currentSchoolYear',
    SCHOOLS: 'schools',
    UPDATED_AT: 'updatedAt',
    ASSETS_PATH: 'assetPath',
    ASSETS_TOKEN: 'assetToken',
    LANGUAGE: 'language',
}

@Injectable()
export class LocalStorageService {
    get currentUser(): any {
        const userString = localStorage.getItem(LocalStorageConst.CURRENT_USER)
        if (userString && userString.length > 0) {
            try {
                return JSON.parse(userString)
            } catch (error) {
                return null
            }
        } else {
            return null
        }
    }

    set currentUser(userToSet: any) {
        userToSet = JSON.stringify(userToSet)
        localStorage.setItem(LocalStorageConst.CURRENT_USER, userToSet)
    }

    get currentSchool(): any {
        const schoolString = localStorage.getItem(LocalStorageConst.CURRENT_SCHOOL)
        try {
            return typeof schoolString === 'string' ? JSON.parse(schoolString) : schoolString
        } catch (e) {
            // console.log(e)
        }
        return null
    }

    set currentSchool(school: any) {
        localStorage.setItem(LocalStorageConst.CURRENT_SCHOOL, JSON.stringify(school))
    }

    get currentSchoolYear(): any {
        const schoolYearString = localStorage.getItem(LocalStorageConst.CURRENT_SCHOOL_YEAR)
        try {
            return typeof schoolYearString === 'string' ? JSON.parse(schoolYearString) : schoolYearString
        } catch (e) {
            // console.log(e)
        }
        return null
    }

    set currentSchoolYear(schoolYear: any) {
        localStorage.setItem(LocalStorageConst.CURRENT_SCHOOL_YEAR, JSON.stringify(schoolYear))
    }

    get schools(): any {
        const schoolString = localStorage.getItem(LocalStorageConst.SCHOOLS)
        try {
            return typeof schoolString === 'string' ? JSON.parse(schoolString) : schoolString
        } catch (e) {
            // console.log(e)
        }
        return []
    }

    set schools(schools: any) {
        localStorage.setItem(LocalStorageConst.SCHOOLS, JSON.stringify(schools))
    }

    get token(): any {
        const value = localStorage.getItem(LocalStorageConst.TOKEN)
        return JSON.parse(value)
    }

    set token(token: any) {
        const value = JSON.stringify(token)
        localStorage.setItem(LocalStorageConst.TOKEN, value)
    }

    get accessToken(): string {
        return this.token?.access_token
    }

    set accessToken(access_token: string) {
        const token = this.token
        token.access_token = access_token
        this.token = token
    }

    get refreshToken(): string {
        return this.token?.refresh_token
    }

    get assetsPath(): string {
        return localStorage.getItem(LocalStorageConst.ASSETS_PATH)
    }

    set assetsPath(path: string) {
        localStorage.setItem(LocalStorageConst.ASSETS_PATH, path)
    }

    get assetsToken(): string {
        return localStorage.getItem(LocalStorageConst.ASSETS_TOKEN)
    }

    set assetsToken(newToken: string) {
        localStorage.setItem(LocalStorageConst.ASSETS_TOKEN, newToken)
    }

    get currentLanguageCode(): string {
        return localStorage.getItem(LocalStorageConst.LANGUAGE)
    }

    set currentLanguageCode(newLanguageCode) {
        localStorage.setItem(LocalStorageConst.LANGUAGE, newLanguageCode)
    }

    get updatedAt() {
        return localStorage.getItem(LocalStorageConst.UPDATED_AT)
    }

    set updatedAt(epoch) {
        localStorage.setItem(LocalStorageConst.UPDATED_AT, epoch)
    }

    public clearAll() {
        localStorage.removeItem(LocalStorageConst.CURRENT_USER)
        localStorage.removeItem(LocalStorageConst.ASSETS_PATH)
        localStorage.removeItem(LocalStorageConst.ASSETS_TOKEN)
        localStorage.removeItem(LocalStorageConst.LANGUAGE)
        localStorage.removeItem(LocalStorageConst.UPDATED_AT)
        localStorage.removeItem(LocalStorageConst.SCHOOLS)
        localStorage.removeItem(LocalStorageConst.CURRENT_SCHOOL)
        localStorage.clear()
    }
}
