import { Injectable, Injector } from '@angular/core'
import { SharedService } from '../../shared/shared.service'
import { ApiPath, DefaultLanguage, HeaderCode } from '../../config/global-const'
import { LocalStorageService } from './index'
import { environment } from '../../../environments/environment'
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'

@Injectable()
export class PvsHttpRequest {
    constructor(private httpClient: HttpClient, private injector: Injector) {
        this._httpClient = httpClient
    }

    private get sharedService(): SharedService {
        if (!this._sharedService) {
            this._sharedService = this.injector.get(SharedService)
        }
        return this._sharedService
    }

    private get localStorageService(): LocalStorageService {
        if (!this._localStorageService) {
            this._localStorageService = this.injector.get(LocalStorageService)
        }
        return this._localStorageService
    }

    public get usingLoadingBar(): boolean {
        return this._usingLoadingBar
    }

    public set usingLoadingBar(value: boolean) {
        this._usingLoadingBar = value
    }
    public static method = {
        GET: 'get',
        POST: 'post',
        PUT: 'put',
        DELETE: 'delete',
    }
    private _sharedService: SharedService
    private _localStorageService: LocalStorageService
    private _httpClient: HttpClient
    private _method: string
    private _url: string
    private _params: any
    private _responseAsFile: boolean
    private _isFormData: boolean
    private _body: any
    private _usingLoadingBar: boolean = true
    private _retryCount: number = 0

    public static get(http: HttpClient, injector: Injector, url, params, responseAsFile) {
        const ins = new PvsHttpRequest(http, injector)
        ins._method = PvsHttpRequest.method.GET
        ins._url = url
        ins._params = params
        ins._responseAsFile = responseAsFile
        return ins
    }

    public static post(http: HttpClient, injector: Injector, url, body, isFormData) {
        const ins = new PvsHttpRequest(http, injector)
        ins._method = PvsHttpRequest.method.POST
        ins._url = url
        ins._body = body
        ins._isFormData = isFormData
        return ins
    }

    public static put(http: HttpClient, injector: Injector, url, body, isFormData) {
        const ins = new PvsHttpRequest(http, injector)
        ins._method = PvsHttpRequest.method.PUT
        ins._url = url
        ins._body = body
        ins._isFormData = isFormData
        return ins
    }

    public static delete(http: HttpClient, injector: Injector, url, params) {
        const ins = new PvsHttpRequest(http, injector)
        ins._method = PvsHttpRequest.method.DELETE
        ins._url = url
        ins._params = params
        return ins
    }

    private createOption(queryParams = null, isFormData = false) {
        const currentLanguage: string = this.localStorageService.currentLanguageCode || DefaultLanguage
        const token = this.localStorageService?.accessToken || 'force-to-error'
        const headers = {
            'x-access-token': token,
            'Accept-Language': currentLanguage,
            'Accept-Platform': 'web',
        }
        if (!isFormData) {
            headers['Content-Type'] = 'application/json'
        }
        let options = {
            headers: new HttpHeaders(headers),
        }
        const sendParams = {}
        for (const [name, value] of Object.entries(queryParams ? queryParams : {})) {
            if (value !== null) {
                sendParams[name] = value
            }
        }
        options = Object.keys(sendParams).length > 0 ? this.setHttpParams(sendParams, options) : options
        return options
    }

    private setHttpParams(obj: any, options: any) {
        options.params = Object.getOwnPropertyNames(obj).reduce((p, key) => p.set(key, obj[key]), new HttpParams())
        return options
    }

    private createHttpRequest() {
        switch (this._method) {
            case PvsHttpRequest.method.GET: {
                const options: any = this.createOption(this._params)
                if (this._responseAsFile) {
                    options.responseType = 'blob'
                }
                return this._httpClient.get(this._url, options)
            }
            case PvsHttpRequest.method.POST:
            case PvsHttpRequest.method.PUT: {
                const options = this.createOption(null, this._isFormData)
                let body = this._body
                if (this._body && typeof this._body !== 'string' && !this._isFormData) {
                    body = JSON.stringify(body)
                }
                if (this._method === 'post') {
                    return this._httpClient.post(this._url, body, options)
                } else {
                    return this._httpClient.put(this._url, body, options)
                }
            }
            case PvsHttpRequest.method.DELETE: {
                const options: any = this.createOption(this._params)
                return this._httpClient.delete(this._url, options)
            }
        }
    }

    private refreshToken(successCallback) {
        const options = this.createOption()
        const body = JSON.stringify({
            accessToken: this.localStorageService.accessToken,
            refreshToken: this.localStorageService.refreshToken,
        })
        const url = environment.apiUrl + ApiPath.AUTH.REFRESH_TOKEN.PATH
        this._httpClient.post(url, body, options).subscribe(
            (res: any) => {
                this.localStorageService.accessToken = res.data.accessToken
                return successCallback()
            },
            (err) => {
                this.sharedService.showAndHandleError(err)
            }
        )
    }

    private async refreshTokenPromise() {
        const options = this.createOption()
        const body = JSON.stringify({
            accessToken: this.localStorageService.accessToken,
            refreshToken: this.localStorageService.refreshToken,
        })
        const url = environment.apiUrl + ApiPath.AUTH.REFRESH_TOKEN.PATH
        try {
            const res: any = await this._httpClient.post(url, body, options).toPromise()
            this.localStorageService.accessToken = res.data.accessToken
            return true
        } catch (err) {
            this.sharedService.showAndHandleError(err)
            return false
        }
    }

    public subscribe(nextCallback?, errorCallback?, completedCallback?) {
        if (this._usingLoadingBar) {
            this.sharedService.setLoadingBar(true)
        }
        const httpRequest = this.createHttpRequest()
        return httpRequest.subscribe(
            (res: any) => {
                if (this._usingLoadingBar) {
                    this.sharedService.setLoadingBar(false)
                }
                if (typeof nextCallback === 'function') {
                    return nextCallback(res)
                }
                return null
            },
            (err) => {
                if (this._usingLoadingBar) {
                    this.sharedService.setLoadingBar(false)
                }
                const errorCode = err?.error?.code
                if (errorCode === HeaderCode.HTTP_TOKEN_EXPIRED && this._retryCount === 0) {
                    this._retryCount += 1
                    return this.refreshToken(() => {
                        this.subscribe(nextCallback, errorCallback, completedCallback)
                    })
                }
                if (typeof errorCallback === 'function') {
                    return errorCallback(err)
                }
                this.sharedService.showAndHandleError(err)
                return null
            },
            () => {
                if (typeof completedCallback === 'function') {
                    return completedCallback()
                }
                return null
            }
        )
    }

    public async toPromise(errorCallback?: Function) {
        if (this._usingLoadingBar) {
            this.sharedService.setLoadingBar(true)
        }
        const httpRequest = this.createHttpRequest()
        try {
            const res = await httpRequest.toPromise()
            if (this._usingLoadingBar) {
                this.sharedService.setLoadingBar(false)
            }
            return res
        } catch (err) {
            const errorCode = err?.error?.code
            if (errorCode === HeaderCode.HTTP_TOKEN_EXPIRED && this._retryCount === 0) {
                this._retryCount += 1
                const success = await this.refreshTokenPromise()
                if (this._usingLoadingBar) {
                    this.sharedService.setLoadingBar(false)
                }
                if (success) {
                    return this.toPromise(errorCallback)
                }
                return null
            }
            if (this._usingLoadingBar) {
                this.sharedService.setLoadingBar(false)
            }
            if (errorCallback) {
                return errorCallback(err)
            }
            this.sharedService.showAndHandleError(err)
            return null
        }
    }
}
