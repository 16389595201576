import { LOCALE_ID, NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { AppComponent } from './app.component'
import { AppRoutingModule } from './app-routing.module'
import { CoreModule } from './@core/core.module'
import { SharedService } from './shared/shared.service'
import { RequestService } from './@core/services/request.service'
import { ThemeModule } from './@theme/theme.module'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import {
    NbMenuModule,
    NbSidebarService,
    NbToastrModule,
    NbDatepickerModule,
    NbDialogModule,
    NB_TIME_PICKER_CONFIG,
} from '@nebular/theme'
import { NbDateFnsDateModule } from '@nebular/date-fns'
import { AngularFireStorageModule } from '@angular/fire/storage'
import { DefaultInterceptor } from './@core/interceptors/default-interceptor'

// setting locale
import { registerLocaleData } from '@angular/common'
import localeVi from '@angular/common/locales/vi'
registerLocaleData(localeVi, 'vi')

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        HttpClientModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        CoreModule.forRoot(),
        ThemeModule.forRoot(),
        NbToastrModule.forRoot(),
        NbMenuModule.forRoot(),
        NbDatepickerModule.forRoot(),
        NbDateFnsDateModule.forRoot({
            format: 'yyyy-MM-dd',
        }),
        NbDialogModule.forRoot(),
        ReactiveFormsModule,
        FormsModule,
        AngularFireStorageModule,
    ],
    providers: [
        SharedService,
        RequestService,
        NbSidebarService,
        { provide: HTTP_INTERCEPTORS, useClass: DefaultInterceptor, multi: true },
        { provide: LOCALE_ID, useValue: 'vi' },
        {
            provide: NB_TIME_PICKER_CONFIG,
            useValue: {},
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
