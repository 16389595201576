export const environment = {
    production: true,
    homeUrl: 'https://baotroongoi.com/',
    apiUrl: 'https://baotroongoi.com/api/',
    cmsUrl: 'https://cms.baotroongoi.com/',
    imagePath: 'https://firebasestorage.googleapis.com/v0/b/bao-tro-on-goi.appspot.com/o/images%2F',
    googleLoginUrl:
        'https://accounts.google.com/o/oauth2/v2/auth?access_type=offline&prompt=consent&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile&response_type=code&client_id=149322500074-i3arc44onj1k726a6imt615g80lm2n6h.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Fcms.baotroongoi.com%2Fauth%2Fgoogle%2Fcallback',
    facebookLoginUrl:
        'https://www.facebook.com/v12.0/dialog/oauth?client_id=200844555423914&redirect_uri=https://glv.pvs-tech.com/auth/facebook/callback',
    appleLoginUrl: '', // todo: update url
}
