import { Injectable, EventEmitter, Injector } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { HeaderCode, RouterPath } from '../config/global-const'
import { serialize } from 'object-to-formdata'
import { LocalStorageService } from 'src/app/@core/utils'
import { NbIconConfig, NbToastrService } from '@nebular/theme'
import { PageEvent } from '@angular/material/paginator'

@Injectable()
export class SharedService {
    // properties ↓
    private _currentUser: any // logged user information: decoded from token or get profile API
    get currentUser() {
        if (!this._currentUser) {
            this._currentUser = this.localStorageService.currentUser
        }
        return this._currentUser
    }
    set currentUser(user) {
        this._currentUser = user
        this.localStorageService.currentUser = user
    }
    // properties ↑

    public loading: EventEmitter<any> = new EventEmitter()
    public load: boolean
    private _loadingCount: number = 0
    private _localStorageService: LocalStorageService

    infoIcon: NbIconConfig = { icon: 'checkmark-outline', pack: 'eva' }
    errorIcon: NbIconConfig = { icon: 'close-outline', pack: 'eva' }

    constructor(private router: Router, private injector: Injector, private toastService: NbToastrService) {}

    public get localStorageService(): LocalStorageService {
        if (!this._localStorageService) {
            this._localStorageService = this.injector.get(LocalStorageService)
        }
        return this._localStorageService
    }

    setLoadingBar(loadingStatus: boolean) {
        if (loadingStatus) {
            this._loadingCount += 1
        } else {
            if (this._loadingCount > 0) {
                this._loadingCount -= 1
            }
        }
        let status = false
        if (this._loadingCount > 0) {
            status = true
        }
        // let load = event
        if (status !== this.load) {
            this.load = status
            this.loading.emit(loadingStatus)
        }
    }

    resetLoadingCount() {
        this._loadingCount = 0
        this.setLoadingBar(false)
    }

    showMessage(message) {
        this.toastService.info(message, 'Thông báo', this.infoIcon)
    }

    showErrorMessage(errorMessage) {
        this.toastService.danger(errorMessage, 'Lỗi', this.errorIcon)
    }

    showAndHandleError(errorEvent) {
        const errorCode = errorEvent?.error?.code
        if (errorCode === HeaderCode.HTTP_PERMISSION_ERROR || errorCode === HeaderCode.HTTP_FORBIDDEN) {
            this.showErrorMessage(errorEvent?.error?.errorMessage || errorEvent?.errorMessage || errorEvent)
            void this.router.navigate([RouterPath.AUTH.LOGIN.FULL_PATH])
            return
        }
        // cannot connect to server
        if (errorEvent?.status === 0) {
            return this.showErrorMessage('Không thể kết nối đến máy chủ')
        }
        this.showErrorMessage(
            errorEvent?.error?.errorMessage || errorEvent?.errorMessage || errorEvent.error || errorEvent
        )
    }

    getParamsFromUrl(route: ActivatedRoute, queryParams: object = {}, pathParams: object = {}, pageEvent?: PageEvent) {
        const params: object = route['snapshot']['queryParams']
        for (const [paramName, paramValue] of Object.entries(params)) {
            if (pageEvent) {
                if (paramName === 'page') {
                    if (!Number.isNaN(parseInt(paramValue))) {
                        pageEvent.pageIndex = paramValue - 1
                    }
                }
                if (paramName === 'size') {
                    if (!Number.isNaN(parseInt(paramValue))) {
                        pageEvent.pageSize = paramValue
                    }
                }
            }
            if (typeof queryParams[paramName] !== 'undefined') {
                if (typeof queryParams[paramName] === 'number') {
                    queryParams[paramName] = parseInt(paramValue)
                } else {
                    queryParams[paramName] = paramValue
                }
                continue
            }
            if (typeof pathParams[paramName] !== 'undefined') {
                if (typeof pathParams[paramName] === 'number') {
                    pathParams[paramName] = parseInt(paramValue)
                } else {
                    pathParams[paramName] = paramValue
                }
            }
        }
    }

    setParamsToUrl(
        router: Router,
        queryParams,
        pathParams,
        pageEvent: PageEvent = null,
        writeHistory: boolean = false
    ) {
        const params: any = {
            ...queryParams,
            ...pathParams,
        }
        if (pageEvent) {
            params.page = pageEvent.pageIndex + 1
            params.size = pageEvent.pageSize
        }
        void router.navigate([], { queryParams: params, replaceUrl: !writeHistory })
    }

    formatAmt(amt: number) {
        if (typeof amt === 'undefined') {
            return undefined
        }
        const formatter = new Intl.NumberFormat('vi-VN', {
            style: 'currency',
            currency: 'VND',
        })
        return formatter.format(amt)
    }

    getFullName(member) {
        let value = member?.firstName
        if (member?.lastName) {
            value = member.lastName + ' ' + value
        }
        if (member?.saintsName) {
            value = member.saintsName + ' ' + value
        }
        if (member?.titles) {
            value = member.titles + ' ' + value
        }
        return value || ''
    }

    getAddress(member) {
        let address1 = member?.address1
        if (address1 && Number.isInteger(parseInt(address1))) {
            address1 = 'Họ ' + address1.toString()
        }
        return [address1, member?.address2, member?.address3].filter((e) => !!e).join(', ')
    }
}
