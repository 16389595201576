import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
// pipes
import { SvgPipe } from './pipes/svg.pipe'
import { CapitalizeFirstCharPipe } from './pipes/capitalize-first-char.pipe'
import { ConfigFilterPipe } from './pipes/config-filter.pipe'
import { ConvertBooleanPipe } from './pipes/convert-boolean.pipe'
import { DateCustomPipe } from './pipes/date-custom.pipe'
import { ImageUrlPipe } from './pipes/image-url.pipe'
import { SingleSvgPipe } from './pipes/single-svg.pipe'
import { SortByPipe } from './pipes/sort-by.pipe'
import { TimeAgoPipe } from './pipes/time-ago.pipe'
import { ParseIsoPipe } from './pipes/parseIso.pipe'
import { PhoneNumberPipe } from './pipes/phone-number'
import { FeedContentPipe } from './pipes/feed-content.pipe'

@NgModule({
    declarations: [
        SvgPipe,
        CapitalizeFirstCharPipe,
        ConfigFilterPipe,
        ConvertBooleanPipe,
        DateCustomPipe,
        ImageUrlPipe,
        SingleSvgPipe,
        SortByPipe,
        TimeAgoPipe,
        ParseIsoPipe,
        PhoneNumberPipe,
        FeedContentPipe,
    ],
    imports: [CommonModule],
    exports: [
        SvgPipe,
        CapitalizeFirstCharPipe,
        ConfigFilterPipe,
        ConvertBooleanPipe,
        DateCustomPipe,
        ImageUrlPipe,
        SingleSvgPipe,
        SortByPipe,
        TimeAgoPipe,
        ParseIsoPipe,
        PhoneNumberPipe,
        FeedContentPipe,
    ],
})
export class PipesModule {}
