import { Injectable } from '@angular/core'
import { ApiPath } from '../../config/global-const'
import { RequestService } from './request.service'
import { LocalStorageService } from '../utils'
import { formatISO, isAfter, subMinutes } from 'date-fns'

@Injectable({
    providedIn: 'root',
})
export class ConfigService {
    constructor(private localStorageService: LocalStorageService, private requestService: RequestService) {}

    public getLatestConfig() {
        const url = this.requestService.createUrl(ApiPath.CONFIG.PATH)
        return this.requestService.requestGet(url)
    }

    public getAndSaveLatestConfig() {
        const updatedAt = this.localStorageService.updatedAt
        const now = new Date()
        const oneMinutesAgo = formatISO(subMinutes(now, 1))
        if (!updatedAt || updatedAt < oneMinutesAgo) {
            const request = this.getLatestConfig()
            request.usingLoadingBar = false
            request.subscribe((response: any) => {
                this.localStorageService.updatedAt = formatISO(now)
                if (response.data) {
                    this.localStorageService.assetsPath = response.data.imagePath
                }
            })
        } else {
            console.log('not refresh config')
        }
    }
    public update(data) {
        const url = this.requestService.createUrl(ApiPath.CONFIG.PATH)
        return this.requestService.requestPut(url, data)
    }
}
