//// Authentication & Authorization
export type AuthGroup = 'ROLE_ADMIN' | 'ROLE_APPROVER' | 'ROLE_COORDINATOR' | 'ROLE_REPRESENTATIVE'

//// Language
export const DefaultLanguage = 'vi'

//// Header code
export const HeaderCode = {
    HTTP_OK: 200,
    HTTP_ERROR: 422,
    HTTP_NOT_FOUND: 404,
    HTTP_PERMISSION_ERROR: 403,
    HTTP_FORBIDDEN: 401,
    HTTP_TOKEN_EXPIRED: 423,
    HTTP_SERVER_ERROR: 500,
    HTTP_ACCOUNT_EXITS: 462,
    HTTP_ACCOUNT_NOT_ACTIVATED: 465,
    HTTP_SOCIAL_CONNECT_REGISTER: 466,
}

//// Duration
export const DurationConst = {
    ALERT_TIME: 3000,
    TOAST_TIME: {
        MESSAGE: 3000,
        ERROR: 10000,
    },
}

/// Firebase
export const FirebaseConst = {}

/// API path
export const ApiPath = {
    CONFIG: {
        PATH: 'config',
    },
    AUTH: {
        LOGIN: {
            PATH: 'access/login',
        },
        SOCIAL_LOGIN: {
            PATH: 'access/social-login',
        },
        GOOGLE_URL: {
            PATH: 'access/google/url',
        },
        REFRESH_TOKEN: {
            PATH: 'access/refresh-token',
        },
        REGISTER: {
            CHECK_PHONE_NUMBER: 'access/register/phone-number/check',
            VERIFY_PHONE_NUMBER: 'access/register/phone-number/verify',
        },
    },
    USER: {
        LOGOUT: 'user/logout',
        PROFILE: 'user/me',
    },
    FEEDBACK: {
        PATH: 'cms/feedback',
        EDIT: {
            PATH: 'cms/feedback/{feedbackId}',
        },
    },
    FUND: {
        PATH: 'fund',
        PERIOD: {
            PATH: 'fund/periods',
            DETAIL: {
                PATH: 'fund/period/{periodId}',
                DEPOSIT: {
                    PATH: 'fund/period/{periodId}/deposits',
                },
                OUTPUT: {
                    PATH: 'fund/period/{periodId}/output',
                },
            },
            CREATE: {
                PATH: 'fund/period',
                GET_INIT: {
                    PATH: 'fund/period/init-create',
                },
            },
        },
        INIT_FUND: {
            PATH: 'fund/init',
        },
    },
    PARISH: {
        PATH: 'parish',
        EDIT: {
            PATH: 'parish/{parishId}',
        },
    },
    GROUP: {
        PATH: 'group',
        DETAIL: {
            PATH: 'group/{groupId}',
            MEMBERS: {
                PATH: 'group/{groupId}/members',
                OUTPUT: {
                    PATH: 'group/{groupId}/members/output',
                },
            },
            ADD_MEMBER: {
                PATH: 'group/{groupId}/add-member/{memberId}',
            },
            REMOVE_MEMBER: {
                PATH: 'group/{groupId}/remove-member/{memberId}',
            },
        },
    },
    MEMBER: {
        PATH: 'member',
        IMPORT_FILE: {
            PATH: 'member/import',
        },
        INCLUDE_GROUP: {
            PATH: 'member/include-group',
        },
        EDIT: {
            PATH: 'member/{memberId}',
        },
        OUTPUT: {
            PATH: 'member/output',
        },
    },
    GALLERY: {
        PATH: 'gallery',
        EDIT: {
            PATH: 'gallery/{galleryId}',
        },
    },
}

/// Routing
export const RouterPath = {
    AUTH: {
        PATH: 'auth',
        LOGIN: {
            PATH: 'login',
            FULL_PATH: 'auth/login',
        },
        GET_LINK: {
            PATH: 'get-link',
            FULL_PATH: 'auth/get-link',
        },
        GOOGLE_CALLBACK: {
            PATH: 'google/callback',
            FULL_PATH: 'auth/google/callback',
        },
        FACEBOOK_CALLBACK: {
            PATH: 'facebook/callback',
            FULL_PATH: 'auth/facebook/callback',
        },
        APPLE_CALLBACK: {
            PATH: 'apple/callback',
            FULL_PATH: 'auth/apple/callback',
        },
    },
    USER: {
        PATH: 'user',
    },
    FUND: {
        TITLE: 'Đóng góp',
        PATH: 'fund',
        FULL_PATH: 'fund',
        DEPOSIT: {
            PATH: 'deposit',
            FULL_PATH: 'fund/deposit',
        },
    },
    PARISH: {
        TITLE: 'Giáo xứ',
        PATH: 'parish',
        FULL_PATH: 'parish',
    },
    ACTIVITY: {
        TITLE: 'Hoạt động',
        PATH: 'activity',
        FULL_PATH: 'activity',
    },
    GROUP: {
        TITLE: 'Nhóm',
        PATH: 'group',
        FULL_PATH: 'group',
        MEMBER: {
            PATH: 'members',
            FULL_PATH: 'group/members',
        },
    },
    MEMBER: {
        TITLE: 'Thành viên',
        PATH: 'member',
        FULL_PATH: 'member',
    },
    FEEDBACK: {
        TITLE: 'Phản hồi',
        PATH: 'feedback',
        FULL_PATH: 'feedback',
    },
    PROFILE: {
        TITLE: 'Tài khoản',
        PATH: 'profile',
        FULL_PATH: 'profile',
    },
    SETTING: {
        TITLE: 'Cài đặt',
        PATH: 'setting',
        FULL_PATH: 'setting',
        GENERAL_CONFIG: {
            TITLE: 'Cài đặt chung',
            PATH: 'general',
            FULL_PATH: 'setting/general',
        },
        INIT_FUND: {
            TITLE: 'Số dư đầu kỳ',
            PATH: 'init-fund',
            FULL_PATH: 'setting/init-fund',
        },
    },
    ERRORS: {
        _500: '500',
        _404: '404',
        PATH: 'errors',
        NO_SCHOOL: {
            PATH: 'no-school',
            FULL_PATH: 'errors/no-school',
        },
    },
}

/// Date
export const DateFormat = {
    DEFAULT_FORMAT: 'DD/MM/YYYY hh:mm:ss',
}

/// SVG
export const BaseSVG: any = {
    baseSVG:
        '<path d="M30.55 12.5l-11.050-11.050c-0.95-0.95-2.15-1.45-3.5-1.45s-2.55 0.5-3.5 1.45l-11.050 11.050c-0.95 0.95-1.45 2.15-1.45 3.5s0.5 2.55 1.45 3.5l11.050 11.050c0.95 0.95 2.2 1.45 3.5 1.45 1.35 0 2.55-0.5 3.5-1.45l11.050-11.050c0.95-0.95 1.45-2.2 1.45-3.5s-0.5-2.55-1.45-3.5z"/>',
    baseOutlineSVG:
        '<path d="M28.75 12.95l-9.7-9.7c-0.8-0.8-1.9-1.25-3.050-1.25s-2.25 0.45-3.050 1.25l-9.7 9.7c-0.8 0.8-1.25 1.9-1.25 3.050s0.45 2.25 1.25 3.050l9.65 9.65c0.8 0.8 1.9 1.25 3.050 1.25s2.25-0.45 3.050-1.25l9.65-9.65c0.8-0.8 1.25-1.9 1.25-3.050s-0.35-2.25-1.15-3.050zM27.4 17.75l-9.65 9.65c-0.45 0.45-1.1 0.75-1.75 0.75s-1.3-0.25-1.75-0.75l-9.65-9.65c-0.45-0.45-0.75-1.1-0.75-1.75s0.25-1.3 0.75-1.75l9.65-9.65c0.45-0.45 1.1-0.75 1.75-0.75s1.3 0.25 1.75 0.75l9.65 9.65c0.45 0.45 0.75 1.1 0.75 1.75s-0.25 1.3-0.75 1.75z"></path>',
}

export const DEFAULT_LIMIT_PAGINATION: number = 20
