import { Injectable } from '@angular/core'
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse } from '@angular/common/http'

import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
@Injectable()
export class DefaultInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!req.headers.has('Accept-Language')) {
            // set default language
            req = req.clone({ headers: req.headers.set('Accept-Language', 'vi') })
        }
        return next.handle(req).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                }
                return event
            })
        )
    }
}
