import { Component } from '@angular/core'
import { SharedService } from '../../../shared/shared.service'

@Component({
    selector: 'ngx-one-column-layout',
    styleUrls: ['./one-column.layout.scss'],
    template: `
        <div *ngIf="sharedService.load" class="slider">
            <div class="line"></div>
            <div class="sub-line inc"></div>
            <div class="sub-line dec"></div>
        </div>
        <nb-layout windowMode>
            <nb-layout-header class="pvs-header" fixed>
                <ngx-header></ngx-header>
            </nb-layout-header>

            <nb-sidebar class="menu-sidebar" tag="menu-sidebar" responsive>
                <ng-content select="nb-menu"></ng-content>
            </nb-sidebar>

            <nb-layout-column class="pvs-page">
                <ng-content select="router-outlet"></ng-content>
            </nb-layout-column>

            <!--            <nb-layout-footer>-->
            <!--                <ngx-footer></ngx-footer>-->
            <!--            </nb-layout-footer>-->
        </nb-layout>
    `,
})
export class OneColumnLayoutComponent {
    constructor(public sharedService: SharedService) {}
}
