import { Injectable, Injector } from '@angular/core'
import { RequestService } from './request.service'
import { ApiPath } from '../../config/global-const'
import { SharedService } from '../../shared/shared.service'

@Injectable({
    providedIn: 'root',
})
export class UserService {
    private _sharedService: SharedService
    constructor(private requestService: RequestService, private injector: Injector) {}

    private get sharedService(): SharedService {
        if (!this._sharedService) {
            this._sharedService = this.injector.get(SharedService)
        }
        return this._sharedService
    }

    getProfile() {
        const url = this.requestService.createUrl(ApiPath.USER.PROFILE)
        return this.requestService.requestGet(url)
    }

    updateProfile(data, avatarFile?) {
        const formData = new FormData()
        if (avatarFile instanceof File) {
            formData.append('avatar', avatarFile)
        }
        formData.append('stringify', JSON.stringify(data))
        const url = this.requestService.createUrl(ApiPath.USER.PROFILE)
        return this.requestService.requestPut(url, formData, true)
    }

    getFullName(user, includeSaintsName: boolean = true): string {
        const params = []
        if (includeSaintsName && user.sainstName) {
            params.push(user.sainstName.trim())
        }
        if (user.lastName) {
            params.push(user.lastName.trim())
        }
        if (user.firstName) {
            params.push(user.firstName.trim())
        }
        return params.join(' ')
    }
}
