import { Injectable } from '@angular/core'
import { ImageCroppedEvent } from 'ngx-image-cropper'

@Injectable()
export class ImageService {
    public imageChangedEvent: any
    public croppedImage: File = null
    public selectedImage: File = null
    public errorText: string = ''
    private fileName: string

    public deinit() {
        this.imageChangedEvent = undefined
        this.croppedImage = undefined
        this.selectedImage = undefined
        this.errorText = ''
    }

    public imageCropped(event: ImageCroppedEvent): File {
        let imageToDrop = event.base64
        let arr = imageToDrop.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n)
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n)
        }
        this.croppedImage = new File([u8arr], this.fileName, { type: mime })
        return this.croppedImage
    }

    public onImageSelected(event: any, maxSize?: number, allowFormats?: string[]) {
        this.errorText = ''
        if (maxSize == null) {
            maxSize = 500 * 1024
        } else {
            maxSize = maxSize * 1024 * 1024
        }
        if (allowFormats == null) {
            allowFormats = ['jpg', 'jpeg']
        }
        const reader = new FileReader()
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0]
            reader.readAsDataURL(file)
            reader.onload = () => {
                if (maxSize < file.size) {
                    this.errorText = 'Ảnh cần nhỏ hơn 500kb'
                    return
                }
                /// check file type
                let parts = file.type.split('/')
                let imageFormat = parts[parts.length - 1].toLowerCase()
                if (!this.isValidFormat(imageFormat, allowFormats)) {
                }
                if (parts[parts.length - 1].toLowerCase() != 'jpg' && parts[parts.length - 1].toLowerCase() != 'jpeg') {
                    this.errorText = 'Ảnh cần dùng định dạng ' + allowFormats
                    return
                }
                this.selectedImage = file
                this.fileName = this.selectedImage.name
                this.imageChangedEvent = event
            }
        }
    }

    private isValidFormat(formatToCheck: string, allowFormats?: string[]): boolean {
        var isValid = false
        for (var index = 0; index < allowFormats.length; index++) {
            if (formatToCheck.toLowerCase() === allowFormats[index]) {
                isValid = true
                break
            }
        }
        return isValid
    }
}
