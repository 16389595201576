import { Pipe, PipeTransform } from '@angular/core'
import { LocalStorageService } from '../../utils'
import { environment } from '../../../../environments/environment'

@Pipe({
    name: 'ImageUrlPipe',
})
export class ImageUrlPipe implements PipeTransform {
    constructor(private localStorageService: LocalStorageService) {}
    transform(value: string, ...args: any[]): string {
        if (!value || typeof value !== 'string') {
            return value
        }
        value = value.trim()
        if (value.includes('http://') || value.includes('https://')) {
            return value
        } else {
            value = (this.localStorageService.assetsPath || environment.imagePath) + value
            // remove blur hash
            value = value.replace(/\?.*$/, '')
            return value + '?alt=media'
        }
    }
}
