import { ModuleWithProviders, NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MatRippleModule } from '@angular/material/core'
import {
    NbActionsModule,
    NbLayoutModule,
    NbMenuModule,
    NbSearchModule,
    NbSidebarModule,
    NbUserModule,
    NbContextMenuModule,
    NbButtonModule,
    NbSelectModule,
    NbIconModule,
    NbThemeModule,
    NbRadioModule,
    NbCardModule,
} from '@nebular/theme'
import { NbEvaIconsModule } from '@nebular/eva-icons'
import { NbSecurityModule } from '@nebular/security'
import { HeaderComponent, FooterComponent } from './components'
import { OneColumnLayoutComponent } from './layout'
import { MatRadioModule } from '@angular/material/radio'
import { MatMenuModule } from '@angular/material/menu'
import { FlexModule } from '@angular/flex-layout'
import { PipesModule } from '../@core/pipes/pipes.module'

const NB_MODULES = [
    NbLayoutModule,
    NbMenuModule,
    NbUserModule,
    NbActionsModule,
    NbSearchModule,
    NbSidebarModule,
    NbContextMenuModule,
    NbSecurityModule,
    NbButtonModule,
    NbSelectModule,
    NbIconModule,
    NbEvaIconsModule,
]
const COMPONENTS = [HeaderComponent, FooterComponent, OneColumnLayoutComponent]
const PIPES = []

@NgModule({
    imports: [
        CommonModule,
        MatRippleModule,
        ...NB_MODULES,
        MatRadioModule,
        MatMenuModule,
        FlexModule,
        NbRadioModule,
        NbCardModule,
        PipesModule,
    ],
    exports: [CommonModule, MatRippleModule, ...PIPES, ...COMPONENTS],
    declarations: [...COMPONENTS, ...PIPES],
})
export class ThemeModule {
    static forRoot(): ModuleWithProviders<ThemeModule> {
        return {
            ngModule: ThemeModule,
            providers: [
                ...NbThemeModule.forRoot({
                    name: 'default',
                }).providers,
            ],
        }
    }
}
