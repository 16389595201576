import { Injectable, Injector } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../../environments/environment'
import { PvsHttpRequest } from '../utils/pvs-http-request'
import _ from 'lodash'

@Injectable()
export class RequestService {
    constructor(private http: HttpClient, private injector: Injector) {}

    public createUrl(apiPath: string, pathParams: object = null) {
        let url = environment.apiUrl + apiPath
        if (pathParams) {
            for (const [name, value] of Object.entries(pathParams)) {
                const stringValue = value === null ? 'null' : value.toString()
                url = url.replace(`{${name}}`, stringValue)
            }
            return url
        }
        return url
    }

    public buildFormStringify(data: any, file?: File, fileFieldName?: string): FormData {
        const clone = _.cloneDeep(data)
        const formData = new FormData()
        if (file && file instanceof File) {
            if (!fileFieldName) {
                throw new Error('Field name of the file is required!')
            }
            formData.append(fileFieldName, file)
            delete clone[fileFieldName]
        }
        formData.append('stringify', JSON.stringify(clone))
        return formData
    }

    public requestGet(url: string, params = null, responseAsFile = false): PvsHttpRequest {
        return PvsHttpRequest.get(this.http, this.injector, url, params, responseAsFile)
    }

    public requestPost(url: string, body = {}, isFormData = false): PvsHttpRequest {
        return PvsHttpRequest.post(this.http, this.injector, url, body, isFormData)
    }

    public requestPut(url: string, body = {}, isFormData = false): PvsHttpRequest {
        return PvsHttpRequest.put(this.http, this.injector, url, body, isFormData)
    }

    public requestDelete(url: string, params = null): PvsHttpRequest {
        return PvsHttpRequest.delete(this.http, this.injector, url, params)
    }
}
