<div class="header-container">
  <div class="logo-container">
    <a
      href="#"
      class="sidebar-toggle"
      matRipple
      [matRippleUnbounded]="true"
      [matRippleCentered]="true"
      (click)="toggleSidebar()"
    >
      <nb-icon [icon]="(materialTheme$ | async) ? 'menu-outline' : 'menu-2-outline'"></nb-icon>
    </a>
    <img src="./assets/images/logo_text.png" width="220" />
  </div>
</div>

<div class="header-container">
  <nb-actions size="small">
    <nb-action
      class="user-action"
      *nbIsGranted="['view', 'user']"
      matRipple
      [matRippleUnbounded]="true"
      [matRippleCentered]="true"
    >
      <nb-user
        [nbContextMenu]="userMenu"
        [onlyPicture]="userPictureOnly"
        [name]="sharedService.currentUser?.firstName"
        [picture]="sharedService.currentUser?.avatarUrl | ImageUrlPipe"
        size="large"
        nbContextMenuTag="user-profile-context-menu"
      >
      </nb-user>
    </nb-action>
  </nb-actions>
</div>
