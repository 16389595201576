import { Injectable } from '@angular/core'
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'
import { NbAuthService } from '@nebular/auth'
import { RouterPath } from '../../config/global-const'
import { tap } from 'rxjs/operators'

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private router: Router, private authService: NbAuthService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.authService.isAuthenticated().pipe(
            tap((authenticated) => {
                if (!authenticated) {
                    void this.router.navigate([RouterPath.AUTH.LOGIN.FULL_PATH])
                }
            })
        )
    }
}
